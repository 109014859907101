/* You can add global styles to this file, and also import other style files */

// Redéfinition des points de rupture
$sm: 576px;
$md: 768px; // Tablette portrait
$lg: 1024px; // Petit écran / tablette paysage
$xl: 1280px; // Moyen écran
$xxl: 1440px; // Grand écran
$xxxl: 1920px; // Écran HD
$xxxxl: 2540px; // Écran 4K

$breakpoints: (
    'sm': $sm,
    'md': $md,
    'lg': $lg,
    'xl': $xl,
    'dxl': $xxl,
    'txl': $xxxl,
    'qxl': $xxxxl
);

@import "/node_modules/primeflex/primeflex.scss";

* {
    font-family: "Inter var", sans-serif;
}

html {
    font-size: 12px;
}

body {
    min-height: 100vh;
    margin: 0;
}

h2 {
    font-weight: 400;
}

.blurred-background {
    z-index: 100;
    backdrop-filter: blur(2px);
}

.no-scrollbars {
    // Hide scroll bars
    // -ms-overflow-style: none;
    // overflow: -moz-scrollbars-none;
    // scrollbar-width: none;

    // &::-webkit-scrollbar {
    //     display: none;
    // }
}

/**
* Extensions PrimeFlex
*/

.h-50 {
    height: 50%;
}

.max-h-50 {
    max-height: 50%;
}